"use client";
import React from "react";
import useEmblaCarousel from "embla-carousel-react";
import Autoplay from "embla-carousel-autoplay";
import Image from "next/image";
import Link from "next/link";

import dynamic from "next/dynamic";

/*
const useEmblaCarousel = dynamic(
  () => import("embla-carousel-react").then((mod) => mod.EmblaCarouselReact),
  { ssr: false }
);*/

export const EmblaCarousel = () => {
  const [emblaRef] = useEmblaCarousel({ loop: false }, [Autoplay()]);

  return (
    <div className="embla" ref={emblaRef}>
      <div className="embla__container">
        <div className="embla__slide">
          <section className="title-heading py-16 md:py-28 lg:py-[250px] md:mt-20 mt-40 ">
            <Image
              src="/images/IMG-20220324-WA0073 (2).jpg"
              alt="Background"
              layout="fill"
              objectFit="cover"
              className="absolute inset-0"
            />
          </section>
        </div>
        <div className="embla__slide">
          <section className="title-heading py-16 md:py-28 lg:py-[250px] md:mt-20 mt-40 ">
            <Image
              src="/images/Aspire-Banners-Home-page-2.png"
              alt="Background"
              layout="fill"
              objectFit="cover"
              className="absolute inset-0"
            />
          </section>
        </div>
        <div className="embla__slide">
          <section className="title-heading py-16 md:py-28 lg:py-[250px] md:mt-20 mt-40 ">
            <Image
              src="/images/Aspire-home-page-banner-3.png"
              alt="Background"
              layout="fill"
              objectFit="cover"
              className="absolute inset-0"
            />
          </section>
        </div>
        <div className="embla__slide">
          <section className="title-heading py-16 md:py-28 lg:py-[250px] md:mt-20 mt-40 ">
            <Image
              src="/images/bendispilates.jpg"
              alt="Background"
              layout="fill"
              objectFit="cover"
              className="absolute inset-0"
            />
          </section>
        </div>

        {/* <div className="embla__slide">
          <section className="title-heading py-16 md:py-28 lg:py-[250px] md:mt-20 mt-40 ">
            <Image
              src="/images/banner5 (1).png"
              alt="Background"
              layout="fill"
              objectFit="cover"
              className="absolute inset-0"
            />
          </section>
        </div>
        <div className="embla__slide">
          <section className="title-heading py-16 md:py-28 lg:py-[250px] md:mt-20 mt-40 ">
            <Image
              src="/images/banner7 (1).png"
              alt="Background"
              layout="fill"
              objectFit="cover"
              className="absolute inset-0"
            />
          </section>
        </div> */}
      </div>
    </div>
  );
};

export default EmblaCarousel;
